
















































































































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { EmailListToEdit, FilePreview, NewFileProps } from '@/models/interfaces'

import { generateFilePreviewObject } from '@/libs/GenerateFilePreviewObject'

import { getStorage, ref, uploadBytesResumable } from '@firebase/storage'

export default Vue.extend({
  name: 'EmailListUpload',
  components: {
    // ActionFeedbackDisplay,
  },
  props: {
    emailListToEdit: {
      type: Object as () => EmailListToEdit | null,
    },
  },
  data: (): {
    newFile: File | null
    newFileProps: NewFileProps
    newFileLineCount: number
    newFileHashProgress: number | null
    newFileUploadProgress: number
    filePreview: FilePreview | null
    filePreviewDefault: FilePreview
  } => {
    return {
      newFile: null,
      newFileProps: {
        zipColumn: false,
      },
      newFileLineCount: 0,
      newFileHashProgress: null,
      newFileUploadProgress: 0,
      filePreview: null,
      filePreviewDefault: {
        headers: [],
        body: [],
        errors: [],
        count: 0,
        isValid: false,
      },
    }
  },
  computed: {
    ...mapGetters(['getUser', 'getUserPermissions', 'getUserById']),
  },
  methods: {
    ...mapActions(['updateActionFeedback', 'updateEmailList']),
    closeUpload() {
      this.$emit('close-modal', true)
    },
    resetFilePreview() {
      this.filePreview = JSON.parse(JSON.stringify(this.filePreviewDefault))
    },
    selectFile(file: File) {
      this.newFile = file
      this.resetFilePreview()
    },
    async previewFile(file: File, fileProps: NewFileProps, hasHeaders = true) {
      // reset filePreview object
      this.resetFilePreview()
      this.filePreview = await generateFilePreviewObject(
        file,
        fileProps,
        hasHeaders,
        5
      )
    },
    newFileReset() {
      this.resetFilePreview()
      this.newFile = null
      this.newFileHashProgress = null
      this.newFileLineCount = 0
      this.newFileUploadProgress = 0
    },
    uploadEmailList() {
      // validate and check access permissions
      if (this.emailListToEdit) {
        if (this.emailListToEdit.userId !== this.getUser.id) {
          console.log('Permission denied')
          return
        }
        if (this.emailListToEdit.ownerTrashed) {
          console.log('Not allowed to upload lists to lists in trash')
        }
      } else {
        console.log('No list selected to upload to')
      }
      // check for new file, and upload
      if (this.newFile && this.emailListToEdit) {
        // path is email_list/{userId}/{listId}/{listId}.csv
        const fileFullPath =
          'email_lists/' +
          this.getUser.id +
          '/' +
          this.emailListToEdit.id +
          '/' +
          this.emailListToEdit.id +
          '.csv'

        const metadata = {
          customMetadata: {
            ownerId: this.getUser.id,
            listId: this.emailListToEdit.id,
            unprocessed: 'true',
            listType: 'sellerList',
          },
        }

        const storage = getStorage()
        const storageRef = ref(storage, fileFullPath)
        const uploadTask = uploadBytesResumable(
          storageRef,
          this.newFile,
          metadata
        )
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            this.newFileUploadProgress = progress
          },
          (error) => {
            console.log(error)
          },
          () => {
            console.log('Upload complete')
            // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //   console.log('File available at', downloadURL)
            // })
          }
        )
        uploadTask.then(() => {
          if (this.newFile && this.emailListToEdit) {
            this.emailListToEdit.fileName = this.newFile.name
          }
          this.updateEmailList({
            emailListNewProps: this.emailListToEdit,
            newFileProps: this.newFileProps,
          })
          this.closeUpload()
          this.newFileReset()
        })
      }
    },
  },
  created() {
    // set the file preview to the default
    this.resetFilePreview()
  },
})
