






import Vue from 'vue'
import EmailListCreate from '@/components/EmailListCreate.vue'

export default Vue.extend({
  name: 'AddList',
  components: {
    EmailListCreate,
  },
})
