var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-4",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Add an Email List to Sell")]),_c('v-card-subtitle',[_vm._v(" Use the form below to add an email list to the marketplace. ")]),(
        _vm.getUserPermissions &&
        (_vm.getUserPermissions.role === 'member' ||
          _vm.getUserPermissions.role === 'admin')
      )?[_c('v-stepper',{staticClass:"ignore-media-filter",attrs:{"value":_vm.emailListCreateStep,"flat":"","alt-labels":"","max-width":"400"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","complete":_vm.emailListCreateStep > 1}},[_vm._v(" Email List Details ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v(" Upload Email List ")])],1)],1),_c('ActionFeedbackDisplay',{attrs:{"actionFeedbackType":"emailListCreate"}}),_c('ActionFeedbackDisplay',{attrs:{"actionFeedbackType":"emailListUpdate"}}),_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.newEmailListId),expression:"!newEmailListId"}],ref:"EmailListCreateForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card-text',[_c('div',{staticClass:"mt-2 overline"},[_vm._v("Email list properties:")]),_c('v-text-field',{attrs:{"label":"Name","rules":_vm.rules.text},model:{value:(_vm.emailListName),callback:function ($$v) {_vm.emailListName=$$v},expression:"emailListName"}}),_c('v-text-field',{attrs:{"label":"Description","rules":_vm.rules.text},model:{value:(_vm.emailListDescription),callback:function ($$v) {_vm.emailListDescription=$$v},expression:"emailListDescription"}}),_c('v-text-field',{attrs:{"label":"Price per name","prepend-icon":"mdi-currency-usd","rules":_vm.rules.number},model:{value:(_vm.emailListPricePerName),callback:function ($$v) {_vm.emailListPricePerName=$$v},expression:"emailListPricePerName"}}),_c('v-switch',{attrs:{"label":"Published"},model:{value:(_vm.emailListPublished),callback:function ($$v) {_vm.emailListPublished=$$v},expression:"emailListPublished"}}),_c('v-checkbox',{attrs:{"rules":_vm.rules.checkbox},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" By clicking this checkbox, I affirm that I have read and agreed to the "),_c('v-dialog',{attrs:{"scrollable":"","width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('a',_vm._g({on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" data exchange agreement ")])]}}],null,false,148383284),model:{value:(_vm.dialogAgreement),callback:function ($$v) {_vm.dialogAgreement=$$v},expression:"dialogAgreement"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Data Exchange Agreement")])]),_c('v-card-text',[_c('data-exchange-agreement')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":function($event){_vm.dialogAgreement = false
                          _vm.emailListAgreement = true}}},[_vm._v(" Agree ")]),_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":function($event){_vm.dialogAgreement = false
                          _vm.emailListAgreement = false}}},[_vm._v(" Disagree ")])],1)],1)],1)],1)]},proxy:true}],null,false,2257238446),model:{value:(_vm.emailListAgreement),callback:function ($$v) {_vm.emailListAgreement=$$v},expression:"emailListAgreement"}}),_c('p',{staticClass:"text-body-1"},[_vm._v(" Once posted, your list will appear in the public market, pending review by our administration team. ")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.addEmailListClick}},[_vm._v(" Submit list and proceed to upload ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.preventDefault();return _vm.resetFormClick.apply(null, arguments)}}},[_vm._v("Clear")])],1)],1),(_vm.newEmailListId)?[_c('v-card-text',[_c('p',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.newEmailList.name))]),_c('br'),_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.newEmailList.description)+" ")]),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Price per name:")]),_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.newEmailList.pricePerName)+" ")]),_c('br')]),_c('v-dialog',{attrs:{"max-width":"800px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-4",attrs:{"color":"accent","large":""}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-cloud-upload ")]),_vm._v(" Upload your list ")],1)]}}],null,false,4077205855),model:{value:(_vm.dialogFile),callback:function ($$v) {_vm.dialogFile=$$v},expression:"dialogFile"}},[_c('EmailListUpload',{attrs:{"emailListToEdit":_vm.newEmailList},on:{"close-modal":_vm.closeUpload}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","to":"/yourlists"}},[_vm._v(" View your lists ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.clearData}},[_vm._v(" Add another list ")])],1)]:_vm._e()]:[_c('v-card-text',[_vm._v(" Your market membership approval is pending. You will be able to post lists once approved. ")])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }