










































































































































































































































































// If text is updated, convert to html with https://www.textfixer.com/html/convert-text-html.php
// then add back in: as of {{ new Date().toLocaleString() }} (&quot;Effective Date&quot;)

import Vue from 'vue'

export default Vue.extend({
  name: 'DataExchangeAgreement',
  data() {
    return {}
  },
})
