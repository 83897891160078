




































































































































































import Vue from 'vue'
import ActionFeedbackDisplay from '@/components/ActionFeedbackDisplay.vue'
import DataExchangeAgreement from '@/components/DataExchangeAgreement.vue'
import EmailListUpload from '@/components/EmailListUpload.vue'
import { mapActions, mapGetters } from 'vuex'
import { EmailListToEdit } from '@/models/interfaces'
// import { EmailListData } from '@/models/interfaces'

export default Vue.extend({
  name: 'EmailListCreate',
  components: {
    ActionFeedbackDisplay,
    DataExchangeAgreement,
    EmailListUpload,
  },
  data: (): {
    emailListName: string
    emailListDescription: string
    emailListPricePerName: number
    emailListTags: string[]
    emailListPublished: boolean
    emailListAgreement: boolean
    emailListCreateStep: number
    rules: {
      text: { (v: string): boolean | string }[]
      number: { (v: string): boolean | string }[]
      checkbox: { (v: boolean): boolean | string }[]
    }
    isValid: boolean
    dialogAgreement: boolean
    dialogFile: boolean
    newEmailListId: string | null
    newEmailList: EmailListToEdit | null
  } => {
    return {
      emailListName: '',
      emailListDescription: '',
      emailListPricePerName: 0,
      emailListTags: [],
      emailListPublished: true,
      emailListAgreement: false,
      emailListCreateStep: 1,
      rules: {
        text: [(v: string) => !!v || 'Field is required'],
        number: [
          (v: string) =>
            !v || /^[0-9]\d*(\.\d+)?$/.test(v) || 'Valid amount is required',
        ],
        checkbox: [(v: boolean) => !!v || 'Checkbox is required'],
      },
      isValid: true,
      dialogAgreement: false,
      dialogFile: false,
      newEmailListId: null,
      newEmailList: null,
    }
  },
  computed: {
    ...mapGetters(['getUser', 'getUserPermissions']),
    form(): Vue & { validate: () => boolean } {
      return this.$refs.EmailListCreateForm as Vue & { validate: () => boolean }
    },
  },
  methods: {
    ...mapActions([
      'addEmailList',
      'updateActionFeedback',
      'resetActionFeedback',
    ]),
    async addEmailListClick() {
      this.validateForm('emailListCreate')
      if (this.isValid) {
        if (this.getUser) {
          this.newEmailListId = await this.addEmailList({
            name: this.emailListName,
            description: this.emailListDescription,
            pricePerName: this.emailListPricePerName,
            published: this.emailListPublished,
            tags: this.emailListTags,
            agreement: this.emailListAgreement,
          })
          if (this.newEmailListId) {
            this.newEmailList = {
              id: this.newEmailListId,
              userId: this.getUser.id,
              name: this.emailListName,
              count: 0,
              description: this.emailListDescription,
              pricePerName: this.emailListPricePerName,
              fileName: '',
              tags: this.emailListTags,
              published: this.emailListPublished,
              ownerTrashed: false,
            }
          }
          console.log('new list db id: ', this.newEmailListId)
          this.emailListCreateStep++
          this.resetFormClick()
        } else {
          this.$router.push('login')
        }
      }
    },
    validateForm(action: string) {
      this.resetActionFeedback(action)
      this.form.validate()
      if (this.isValid) {
        return true
      } else {
        this.updateActionFeedback({
          type: action,
          result: {
            state: 'error',
            messages: ['Oops, errors detected! Check form and try again.'],
          },
        })
        return false
      }
    },
    closeUpload() {
      this.dialogFile = false
    },
    resetFormClick() {
      ;(this.$refs.EmailListCreateForm as HTMLFormElement).reset()
    },
    clearData() {
      this.resetFormClick()
      this.emailListName = ''
      this.emailListDescription = ''
      this.emailListPricePerName = 0
      this.emailListTags = []
      this.emailListPublished = true
      this.emailListAgreement = false
      this.emailListCreateStep = 1
      this.isValid = true
      this.dialogAgreement = false
      this.dialogFile = false
      this.newEmailListId = null
      this.newEmailList = null
    },
  },
})
